import { Input as FlipKitInput } from '@flipgrid/flipkit';
import { forwardRef, useContext } from 'react';

import GlobalContext from '~/contexts/globalContext';

import type { InputProps } from '@flipgrid/flipkit/dist/src/Input/InputTypes';
import type { RemoveIndex } from 'types';

type Props = Omit<RemoveIndex<InputProps>, 'type'> &
  React.InputHTMLAttributes<HTMLInputElement> & {
    defaultValue?: string;
    name: string; // Required for formData
    type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'date'; // Remove once 'date' added to Flipkit
  };

const Input = forwardRef<HTMLInputElement, Props>(({ label, name, defaultValue, type, ...rest }, ref) => {
  const globalContext = useContext(GlobalContext);

  return (
    <FlipKitInput
      {...rest}
      ref={ref}
      announceLiveMessage={globalContext.announceLiveMessage}
      label={label}
      name={name}
      defaultValue={defaultValue}
      // @ts-expect-error
      type={type}
    />
  );
});

export default Input;
